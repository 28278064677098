import getConfig from "next/config";
import Image from "next/image";
import useSWR from "swr";

import { twMerge } from "tailwind-merge";

import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faRss } from "@fortawesome/free-solid-svg-icons/faRss";

import FontAwesomeIcon from "components/FontAwesomeIcon";
import { baseFetcher } from "utils/baseFetcher";

import style from "./style.module.scss";

const { publicRuntimeConfig } = getConfig();

const footerUrl = (slug: string) =>
  `https://www.smartkarma.com/home/wp-json/menus/v1/menus/${slug}`;

const Footer = ({}: any) => {
  const { data: column1 } = useSWR(
    footerUrl("footer-column-1-solutions"),
    baseFetcher
  );
  const { data: column2 } = useSWR(
    footerUrl("footer-column-2-about"),
    baseFetcher
  );
  const { data: column3 } = useSWR(
    footerUrl("footer-column-3-legal"),
    baseFetcher
  );
  const { data: column4 } = useSWR(
    footerUrl("footer-column-4-contact"),
    baseFetcher
  );

  return (
    <div className={twMerge(style.container, "bg-active-black")}>
      <section className="p-[25px] pb-0 flex justify-center">
        <nav className="flex-1 lg:max-w-[1278px]">
          <ul
            className={twMerge(style.footer_links_row, "flex-col md:flex-row")}
          >
            <li className={style.footer_links_col}>
              <div className={style.footer_link_heading}>Solutions</div>
              <ul>
                {column1?.items?.map?.((item: any) => (
                  <li key={item.ID}>
                    <a href={item.url} className={style.footer_link}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className={style.footer_links_col}>
              <div className={style.footer_link_heading}>About</div>
              <ul>
                {column2?.items?.map?.((item: any) => (
                  <li key={item.ID}>
                    <a href={item.url} className={style.footer_link}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className={style.footer_links_col}>
              <div className={style.footer_link_heading}>Legal</div>
              <ul>
                {column3?.items?.map?.((item: any) => (
                  <li key={item.ID}>
                    <a href={item.url} className={style.footer_link}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className={style.footer_links_col}>
              <div className={style.footer_link_heading}>Contact</div>
              <ul>
                {column4?.items?.map?.((item: any) => (
                  <li key={item.ID}>
                    <a href={item.url} className={style.footer_link}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className={style.footer_links_col}>
              <div className={style.footer_link_heading}>Download</div>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://itunes.apple.com/app/apple-store/id1363869384?pt=118785049&ct=lp-footer&mt=8"
                    className={style.footer_link}
                  >
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <Image
                      alt="Apple download"
                      src={require("../../assets/sk_ios_download.png")}
                      width={120}
                      height={40}
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.smartkarma.app&utm_source=landing-page&utm_campaign=playstore-badge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  >
                    <Image
                      alt="Google download"
                      src={require("../../assets/sk_android_download.png")}
                      width={134}
                      height={40}
                    />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </section>
      <section className="flex justify-center align-middle border-0 border-t border-solid border-white/20 p-[25px]">
        <div className="flex-1 flex flex-col md:flex-row justify-between lg:max-w-[1278px]">
          <p className={twMerge(style.small_footer_text, "leading-[25px] m-0")}>
            Copyright © 2022 Smartkarma Innovations Pte Ltd. All Rights
            Reserved.{" "}
            {publicRuntimeConfig?.version && `v${publicRuntimeConfig?.version}`}
          </p>
          <div className={style.footer_social}>
            <nav className={style.footer_social_links}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/smartkarma"
                title="Twitter"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/smartkarmahq/"
                title="Facebook"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://sg.linkedin.com/company/smartkarma-innovations-pte-ltd"
                title="LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCQmXoiLTif8GiamyRni2YkQ"
                title="Youtube"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://feeds.smartkarma.com/feed.rss"
                title="RSS"
              >
                <FontAwesomeIcon icon={faRss} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/smartkarma"
                title="Github"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </nav>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
